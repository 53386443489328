import gql from 'graphql-tag';
import { InView } from 'react-intersection-observer';
import { useParams, Redirect, useLocation } from '../dwouter';
import { StyledHeader as Header, headerFragmentFactory } from '../components/Header';
import { StyledCoCoPageHeadlineInHeader as CoCoPageHeadlineInHeader } from '../components/CoCo/CoCoPageHeadlineInHeader.jsx';
import { StyledFooter as Footer, footerFragmentFactory } from '../components/Footer';
import { StyledQuickNavigationList as QuickNavigationList } from '../components/a11y/QuickNavigationList.jsx';
import { BrowserOnlyBreakingBanner } from '../components/BreakingBanner';
import { StyledPageHeadline as PageHeadline } from '../components/PageHeadline.jsx';
import { StyledA11yLinkTargetMarker as A11yLinkTargetMarker } from '../components/a11y/A11yLinkTargetMarker.jsx';
import { ids } from '../utils/staticElementIds';

import { PageWithData, pageWithDataFragment } from './PageWithData.jsx';
import { NotFoundPage } from './NotFoundPage.jsx';
import { PageHeaderMetadata, pageHeaderMetadataFragment } from '../components/PageHeaderMetadata.jsx';
import { isPathMatching } from '../components/commons/PathAnalyser';
import {
  StyledHeadlinesList as HeadlinesList,
  headlinesListFragment,
  darkHeadlinesListStyles,
} from '../components/ContentBlocks/HeadlinesList';

import { withTheme } from '../components/higherOrderComponents/withTheme.jsx';
import { useLocationQueryParam } from '../components/hooks/useLocationQueryParam';
import { objectToQueryParams } from '../utils/url';
import { useHeaderColorToggle } from '../components/hooks/useHeaderColorToggle';
import { useTopOffsetHeaderThreshold } from '../components/hooks/useTopOffsetHeaderThreshold';

export const headlinesOverviewQuery = () => {
  const headerFragment = headerFragmentFactory();
  const footerFragment = footerFragmentFactory();
  return gql`
  query headlinesOverviewData($lang: Language!, $id: Int, $amount: Int) {
    headlinesOverview(lang: $lang) {
      id: originId
      pageHeadline
      ...${pageWithDataFragment.name}
      ...${headerFragment.name}
      ...${footerFragment.name}
      ...${pageHeaderMetadataFragment.name}
      last7DaysContents(amount: $amount) {
        ...${headlinesListFragment.name}
      }
    }
  }
  ${pageWithDataFragment.fragment()}
  ${headerFragment.fragment()}
  ${footerFragment.fragment()}
  ${pageHeaderMetadataFragment.fragment()}
  ${headlinesListFragment.fragment()}
`;
};

export const HeadlinesOverviewPageNoQuery = ({ content, className }) => {
  const { onChangeInViewListenerForHeaderSwitch, forcedOffsetParentForHeaderToggleClassName } = useHeaderColorToggle();
  const topOffsetHeaderThreshold = useTopOffsetHeaderThreshold();

  return (
    <>
      <PageHeaderMetadata content={content}/>
      <QuickNavigationList content={content}/>
      <div className={className}>
        <Header content={content}>
          {({ isHeaderInWhiteState }) => (isHeaderInWhiteState
            ? (
              <CoCoPageHeadlineInHeader navigation={content} />
            )
            : null
          )}
        </Header>
        <BrowserOnlyBreakingBanner />
        <A11yLinkTargetMarker id={ids.quickNav.mainContent} className={forcedOffsetParentForHeaderToggleClassName}>
          <InView as="div" onChange={onChangeInViewListenerForHeaderSwitch} rootMargin={topOffsetHeaderThreshold}>
            <PageHeadline>{content.pageHeadline}</PageHeadline>
          </InView>
          <HeadlinesList contents={content.last7DaysContents}
            headlineTranslation="content_block.headlines_list.headline"
            pageSectionId="headlines-list"
          />
        </A11yLinkTargetMarker>
        <Footer content={content}/>
      </div>
    </>
  );
};

export const HeadlinesOverviewPageNoQueryWithTheme = withTheme(HeadlinesOverviewPageNoQuery)(darkHeadlinesListStyles);

export const HeadlinesOverviewPage = () => {
  const { langCode } = useParams();
  const shortParam = useLocationQueryParam('short');
  const amount = shortParam === '1' ? 21 : undefined;
  const searchParams = objectToQueryParams(amount ? { amount } : {});

  const queryDef = {
    path: `${langCode}/headlines-overview?${searchParams}`,
    depts: [langCode, amount],
  };
  const [{ pathname, search }] = useLocation();

  return (
    <PageWithData queryDef={queryDef} langBaseContentFn={data => data.headlinesOverview}>
      {({ headlinesOverview }) => {
        if (!headlinesOverview) {
          return (
            <NotFoundPage />
          );
        }
        const { namedUrl } = headlinesOverview;

        if (!isPathMatching({ namedUrl, pathname })) {
          return <Redirect to={{ pathname: namedUrl, search }} />;
        }

        return (
          <HeadlinesOverviewPageNoQueryWithTheme content={headlinesOverview} />
        );
      }}
    </PageWithData>
  );
};
