import { memo } from 'react';
import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import { jsonLdScriptFragment, JsonLdScript } from '../JsonLdScript.jsx';
import { headerFragmentFactory, StyledHeader as Header } from '../Header';
import { footerFragmentFactory, StyledFooter as Footer } from '../Footer';
import { StyledContentPageLayout as ContentPageLayout, darkContentPageLayoutStyles } from '../layouts/ContentPageLayout.jsx';
import { darkRecommendedZoneStyles, recommendedZoneFragment, StyledRecommendedZone as RecommendedZone } from '../zones/RecommendedZone.jsx';
import { TopStoryZone } from '../zones/TopStoryZone/index.jsx';
import { darkDetailsZoneStyles, DetailsZone, detailsZoneFragmentFactory } from '../zones/DetailsZone.jsx';
import { OnDetailPageContext } from '../PageEmbeddingContext.jsx';
import { StyledQuickNavigationList as QuickNavigationList } from '../a11y/QuickNavigationList.jsx';
import { sharingIconsInHeaderStyles, StyledContentDetailActions as ContentDetailActions } from '../ContentDetailActions';
import { ImageSharingMetadata, imageSharingMetadataFragment } from '../ImageSharingMetadata.jsx';
import { detailPageHeaderMetadataFragment, PageHeaderMetadata, pageHeaderMetadataFragment } from '../PageHeaderMetadata.jsx';
import { BrowserOnlyBreakingBanner } from '../BreakingBanner';
import { makeMemoFragment } from '../../utils/graphql';
import { StyledPremiumGptSlot as PremiumGptSlot } from '../GptSlot';
import { articlePremiumSlotConfig } from '../GptSlot/slotConfigs';
import { useMobileAppContext } from '../hooks/mobileAppContext';
import { TaboolaLoadScript } from '../Taboola/TaboolaLoadScript';
import { TaboolaWidget } from '../Taboola/TaboolaWidget';
import { isArticle } from '../../utils/contentUtils';

export const contentDetailsFragmentFactory = ({ type }) => makeMemoFragment(({
  name: `${type}ContentDetails`,
  fragment() {
    const detailsZoneFragment = detailsZoneFragmentFactory({ type });
    const headerFragment = headerFragmentFactory();
    const footerFragment = footerFragmentFactory();
    return gql`fragment ${this.name} on ${type} {
            footer {
              policyId
              legalId
            }
            ...${pageHeaderMetadataFragment.name}
            ...${detailPageHeaderMetadataFragment.name}
            ...${jsonLdScriptFragment.name}
            ...${headerFragment.name}
            ...${detailsZoneFragment.name}
            ...${recommendedZoneFragment.name}
            ...${footerFragment.name}
            ...${imageSharingMetadataFragment.name}
          }
        ${pageHeaderMetadataFragment.fragment()}
        ${detailPageHeaderMetadataFragment.fragment()}
        ${jsonLdScriptFragment.fragment()}
        ${headerFragment.fragment()}
        ${detailsZoneFragment.fragment()}
        ${recommendedZoneFragment.fragment()}
        ${footerFragment.fragment()}
        ${imageSharingMetadataFragment.fragment()}
    `;
  },
}));
const RecommendedZoneMemo = memo(RecommendedZone);
const TopStoryZoneMemo = memo(TopStoryZone);

export const ContentDetails = ({ content, className }) => {
  const { isMobileMode } = useMobileAppContext();
  const { policyId, legalId } = content.footer;
  const hideTopStoryZone = isMobileMode && [legalId, policyId].includes(content.id);

  return (
    <OnDetailPageContext>
      <PageHeaderMetadata content={content} />
      <ImageSharingMetadata content={content}/>
      {isArticle(content) && <TaboolaLoadScript /> }
      <JsonLdScript content={content}/>
      <QuickNavigationList content={content}/>
      <div className={className}>
        <Header content={content}>
          {({ isHeaderInWhiteState }) => (isHeaderInWhiteState ? (
            <ContentDetailActions
              content={content}
              isInHeader
              className="sharing-icons"
            />
          ) : null
          )}
        </Header>
        <BrowserOnlyBreakingBanner />
        <PremiumGptSlot slotConfig={articlePremiumSlotConfig}/>
        <ContentPageLayout
          DetailZoneFn={
            () => <DetailsZone content={content} />
          }
          AlternatingColorZoneConfig={{
            compFn: () => (
              <>
                <RecommendedZoneMemo content={content}/>
                {isArticle(content) && <TaboolaWidget containerId="taboola-below-article-thumbnails"/> }
                {!hideTopStoryZone && <TopStoryZoneMemo content={content}/>}
              </>
            ),
          }}
        />
        <Footer content={content}/>
      </div>
    </OnDetailPageContext>
  );
};

export const darkContentDetailsStyles = cx(
  darkContentPageLayoutStyles,
  darkDetailsZoneStyles,
  darkRecommendedZoneStyles,
);

export const StyledContentDetails = styled(ContentDetails)`
  ${sharingIconsInHeaderStyles}
`;
