import gql from 'graphql-tag';

import { NotFoundPage } from '../../../pages/NotFoundPage.jsx';
import { headerFragmentFactory, StyledHeader as Header } from '../../Header';
import { footerFragmentFactory, StyledFooter as Footer } from '../../Footer';
import { navigationCoCoFragment, StyledNavigationCoCo as NavigationCoCo } from './NavigationCoCo.jsx';
import { StyledQuickNavigationList as QuickNavigationList } from '../../a11y/QuickNavigationList.jsx';
import { StyledPremiumGptSlot as PremiumGptSlot } from '../../GptSlot';
import { overviewPremiumSlotConfig } from '../../GptSlot/slotConfigs';

import { pageHeaderMetadataFragment, PageHeaderMetadata } from '../../PageHeaderMetadata.jsx';
import { languageNotificationFragment, StyledLanguageNotification as LanguageNotification } from '../../LanguageNotification.jsx';
import { isHomePage } from '../../../utils/contentUtils';
import { coCoPageHeadlineInHeaderFragment, StyledCoCoPageHeadlineInHeader as CoCoPageHeadlineInHeader } from '../CoCoPageHeadlineInHeader.jsx';
import { BrowserOnlyBreakingBanner } from '../../BreakingBanner';
import { useSetPreferredLanguageEffect } from '../../hooks/useSetPreferredLanguageEffect';
import { makeMemoFragment } from '../../../utils/graphql';

export const navigationFragment = makeMemoFragment({
  name: 'NavigationWithFallback',
  fragment() {
    const headerFragment = headerFragmentFactory();
    const footerFragment = footerFragmentFactory();
    return gql`fragment ${this.name} on Navigation {
        name
        visibleInWebApp
        pageTitle
        metaDescription
        ...${pageHeaderMetadataFragment.name}
        ...${coCoPageHeadlineInHeaderFragment.name}
        ...${navigationCoCoFragment.name}
        ...${headerFragment.name}
        ...${footerFragment.name}
        ...${languageNotificationFragment.name}
      }
      ${pageHeaderMetadataFragment.fragment()}
      ${coCoPageHeadlineInHeaderFragment.fragment()}
      ${navigationCoCoFragment.fragment()}
      ${headerFragment.fragment()}
      ${footerFragment.fragment()}
      ${languageNotificationFragment.fragment()}
     `;
  },
});

export const NavigationWithFallback = ({ navigation }) => {
  useSetPreferredLanguageEffect(navigation);

  if (!navigation.visibleInWebApp || !navigation.contentComposition) {
    return (
      <NotFoundPage/>
    );
  }
  return (
    <>
      <PageHeaderMetadata content={navigation}/>
      <QuickNavigationList content={navigation}/>
      <Header forcedBlueState={isHomePage(navigation)} content={navigation}>
        {({ isHeaderInWhiteState }) => (isHeaderInWhiteState
          ? (
            <CoCoPageHeadlineInHeader navigation={navigation}/>
          )
          : null
        )}
      </Header>
      <BrowserOnlyBreakingBanner/>
      <LanguageNotification navigation={navigation}/>
      <PremiumGptSlot slotConfig={overviewPremiumSlotConfig}/>
      <NavigationCoCo navigation={navigation} />
      <Footer content={navigation}/>
    </>
  );
};
